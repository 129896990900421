import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import Button from 'components/ui/button/button';
import './login-facilitator.scss';

const LoginFacilitator = (props) => {
	let {
		isLoading,
		languageId,
		email,
		password,
		feedback,
		handleInput,
		handleLogin,
		deviceInfo,
		setFacilitatorComponentType,
	} = props;

	return (
		<div className={'LoginFacilitator ' + deviceInfo.orientation}>
			<div className="LoginFacilitator-title">
				<span>{getText(loginUiTexts.facilitator, languageId)}</span>
			</div>
			<div className="LoginFacilitator-body">
				<form className="LoginFacilitator-form" onSubmit={(e) => {handleLogin(e);}}>
					<input
						name="email"
						type="text"
						placeholder={getText(loginUiTexts.email, languageId)} 
						autoComplete="section-facilitatorlogin email"
						value={email ? email : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="password"
						type="password"
						placeholder={getText(loginUiTexts.password, languageId)}
						autoComplete="section-facilitatorlogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<div 
						className="LoginFacilitator-resetPasswordBtn"
						onClick={()=>{setFacilitatorComponentType('resetPassword');}}
					><span>{getText(loginUiTexts.forgotPassword, languageId)}</span></div>
					<div className="LoginFacilitator-loginBtn">
						<Button 
							isLoading={isLoading}
							classes={['login']}
							text={getText(loginUiTexts.login, languageId)}
							onClick={() => {handleLogin();}}
							type='submit'
						/>
					</div>
				</form>
				<div 
					className="LoginFacilitator-createUserBtn"
					onClick={()=>{setFacilitatorComponentType('createUser');}}
				><span>{getText(loginUiTexts.createNewUser, languageId)}</span></div>
				<div className="LoginFacilitator-errorMessage">
					<span>{feedback}</span>
				</div> 
			</div>
		</div>
	);
};

LoginFacilitator.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	setFacilitatorComponentType: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired,
};

export default LoginFacilitator;