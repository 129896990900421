// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const generalUiTexts = {
	safetyTraining: {
		da: 'Sikkerhedstræning',
		en: 'Safety training',
	},
	start: {
		da: 'Start',
		en: 'Start',
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
	}
};


module.exports = {
	generalUiTexts
};