import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import Character from 'components/ui/character/character';
import Audio from 'components/ui/audio/audio';
import SpeechBubble from 'components/ui/speech-bubble/speech-bubble';
import './instructions.scss';

const Instructions = ({languageId, moduleData, taskData, deviceInfo, shouldFadeSpeech = false}) => {

	return (
		<div className={
			'Instructions ' + 
			(taskData.background ? taskData.background : moduleData.background) +
			' ' + 
			deviceInfo.orientation
		}>
			<div className="Instructions-content">
				{/* Speech & audio */}
				{(taskData.title || taskData.text) && 
					<SpeechBubble 
						type="instructions" 
						taskId={taskData.id} 
						deviceInfo={deviceInfo} 
						shouldFadeSpeech={shouldFadeSpeech}
					>
						{taskData.title && <div className="Instructions-title">
							{getText(taskData.title, languageId)}
						</div>}
						{taskData.text && <div className="Instructions-text">
							{renderMarkdown(getText(taskData.text, languageId))}
						</div>}
						<div className="Instructions-audio">
							<Audio 
								type='task-intro'
								color='blue'
								fileName={languageId + '-' + taskData.taskId}
							/>
						</div>
					</SpeechBubble>
				}

				{/* Characters */}
				{taskData.characters.map((characterId) => {
					return (<div key={characterId} className={'Instructions-character ' + characterId}>
						<Character 
							page="instructions" 
							characterId={characterId}
						/>
					</div>);
				})}
			</div>
		</div>
	);
};

Instructions.propTypes = {
	languageId: PropTypes.string.isRequired,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	shouldFadeSpeech: PropTypes.bool,
};

export default Instructions;
