// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const errorUiTexts = {
	unknownError: {
		da: 'Fejl: ukendt fejl.',
		en: 'Error: unknown error.',
	},

	/* Cookies not accepted */
	cookiesNotAccepted: {
		da: `Du skal godkende brug af cookies for at kunne logge ind.`,
		en: 'You have to accept use of cookies to log in.',
	},

	/* Create user (facilitator) */
	emptyFields: {
		da: 'Du skal udfylde alle felter.',
		en: 'Please fill out all fields.',
	},
	emailExists: {
		da: 'Der findes allerede en bruger med denne email.',
		en: 'This email is already in use.',
	},
	invalidPassword: {
		da: 'Dit password skal være på mindst 8 tegn.',
		en: 'Your password has to be at least 8 characters.',
	},
	noPasswordMatch: {
		da: 'Password skal være ens i begge felter.',
		en: 'Passwords do not match.',
	},

	/* Login / reset password (facilitator) */
	emailNotFound: {
		da: 'Der findes ingen bruger med denne email.',
		en: 'There is no user linked to this email.',
	},
	invalidEmail: {
		da: 'Din email er ikke gyldig.',
		en: 'This email is not valid.',
	},
	invalidEmailPasswordFormat: {
		da: 'Fejl. Check om din email og password er korrekte.',
		en: 'Error. Check if your email and password are correct.',
	},
	
	/* Login (player) */
	emptyUserId: {
		da: 'Husk at udfylde dit medarbejder-nr.',
		en: 'Please enter your employee no.',
	},
	invalidUserId: {
		da: 'Det indtastede nummer er forkert. Husk det skal være på 8 cifre.',
		en: 'Invalid number. Remember it must be 8 digits.',
	}
};

module.exports = {
	errorUiTexts
};