import {useEffect, useState} from 'react';
import firebaseInit from 'firebase-init';
import {getBrowserLanguage} from 'helpers/language-helper';
import {getOrientation, getAspectRatio, checkIfInStandaloneMode} from 'helpers/device-helper';
import LoginController from 'components/login/login-controller';
import ImageLoader from './ui/image-loader/image-loader';
import './app.scss';

function App() {
	/* Device info */
	const [deviceInfo, setDeviceInfo] = useState(() => {
		return {
			isInStandaloneMode: checkIfInStandaloneMode(),
			isUsingTouch: false,
			orientation: getOrientation(),
			aspectRatio: getAspectRatio()
		};
	});

	/**
	 * User resized window, update orientation and aspect ratio
	 */
	const handleWindowResize = () => {
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.orientation = getOrientation();
		newDeviceInfo.aspectRatio = getAspectRatio();
		setDeviceInfo(newDeviceInfo);
	};

	/* Language id */
	const browserLanguageId = getBrowserLanguage();
	
	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', handleWindowResize, false);

		/* Component will ummount */
		return () => {
			if (document.removeEventListener) window.removeEventListener('resize', handleWindowResize, false);
		};
	});

	const scrollToTop = () => {
		const AppDiv = document.getElementById('App');
		if (AppDiv) AppDiv.scrollTop = 0;
	};

	return (
		<div id="App" className={'App ' + deviceInfo.orientation}>
			<LoginController deviceInfo={deviceInfo} languageId={browserLanguageId} scrollToTop={scrollToTop} />
			<ImageLoader type="basic" />
		</div>
	);
}

export default App;
