import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyCBul02OW_TYdWIkVLbkIR4gBbrGX1XJTw',
		authDomain: 'cgl-jeudan-test.firebaseapp.com',
		projectId: 'cgl-jeudan-test',
		storageBucket: 'cgl-jeudan-test.appspot.com',
		messagingSenderId: '670505524927',
		appId: '1:670505524927:web:c3d0722962f7367da9f00a'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyC6zZSfspyfNHrXshznuKwFAzE2oJuFuvo',
		authDomain: 'cgl-jeudan-production.firebaseapp.com',
		projectId: 'cgl-jeudan-production',
		storageBucket: 'cgl-jeudan-production.appspot.com',
		messagingSenderId: '265851514144',
		appId: '1:265851514144:web:83c0c691a1a38f71abfb45'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;