import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSprings, animated} from 'react-spring';
import {useDrag} from 'react-use-gesture';
import { getText } from 'helpers/language-helper';
import Audio from 'components/ui/audio/audio';
import './carousel.scss';

const Carousel = ({taskId, currentStepId, languageId, optionType, options, selectOption, deviceInfo}) => {
	/* Layout */
	const [pixelsPerEm, setPixelsPerEm] = useState(0);
	let optionWidthEm = 35.7;
	let carouselWidthEm = 55.6;
	let optionDistanceEm = (carouselWidthEm - optionWidthEm) / 3.;

	if (deviceInfo.orientation === 'portrait') {
		optionWidthEm = 30;
		carouselWidthEm = 37;
		optionDistanceEm = (carouselWidthEm - optionWidthEm) / 3.;
	}
	
	/* Springs */
	const [currentIndex, setCurrentIndex] = useState(0);
	const index = useRef(0);
	const [springProps, setSpringProps] = useSprings(options.length, (i) => {
		return {
			x: i * (optionWidthEm + (optionDistanceEm / 2.)) + optionDistanceEm,
			display: 'block',
			isSelected: (i === 0 ? true : false)
		};
	});

	/* Drag gesture */
	const bind = useDrag(({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
		const mxEm = (pixelsPerEm > 0 ? mx / pixelsPerEm : 0);
		let distanceEm = (pixelsPerEm > 0 ? distance / pixelsPerEm : 0);
		let divisionPoint = 2.;
		if (deviceInfo.orientation === 'portrait') {
			distanceEm = distance;
			divisionPoint = 3.2;
		}

		if (active && distanceEm > optionWidthEm / divisionPoint) {
			let value = Math.min(index.current + (xDir > 0 ? -1 : 1), options.length - 1);
			value = Math.max(value, 0);
			setCurrentIndex(value);
			cancel(index.current = value);
		}

		setSpringProps((i) => {
			if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
			const x = (i - index.current) * (optionWidthEm + (optionDistanceEm / 2.)) 
				+ optionDistanceEm + (active ? mxEm : 0);
			return { x, display: 'block', isSelected: (i === index.current ? true : false) };
		});
	});

	/* Component did mount */
	useEffect(() => {
		/* Get pixels per em */
		const carouselWidthPx = (document.getElementById('carousel') 
			? document.getElementById('carousel').offsetWidth 
			: 0
		);
		setPixelsPerEm(carouselWidthPx / carouselWidthEm);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceInfo.orientation]);

	return (
		<div id="carousel" className="Carousel">
			<div className='Carousel-options'>
				{springProps.map(({ x, display}, i) => {
					const optionData = options[i];
					return (
						<animated.div 
							{...bind()} 
							key={i} 
							style={{
								display, 
								transform: x.to((x) => {return `translate3d(${x}em,0,0)`;})
							}} 
							className={'Carousel-option ' + optionType + ' ' + deviceInfo.orientation}
						>
							<span>{getText(optionData.text, languageId)}</span>
							<div className="Carousel-selectBtn" onClick={() => {selectOption(optionData.id);}}/>
							<div className='Carousel-optionAudio'>
								<Audio 
									type='task-option'
									fileName={languageId + '-' + taskId + '-' + currentStepId + '-' + optionData.id}
								/>
							</div>
						</animated.div>
					);
				})
				}
			</div>
			{options.length > 1 && <div className="Carousel-navigation">
				{options.map((_, index) => {
					return (
						<div 
							key={index} 
							className={'Carousel-navigationDot' + (index === currentIndex ? ' selected' : '')}
						>
							<svg width="14" height="14" viewBox="0 0 14 14"><circle cx="7" cy="7" r="7"/></svg>
						</div>
					);
				})}
			</div>}
		</div>
	);
};

Carousel.propTypes = {
	taskId: PropTypes.string.isRequired,
	currentStepId: PropTypes.string.isRequired,
	languageId: PropTypes.string.isRequired,
	optionType: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	selectOption: PropTypes.func.isRequired,
	deviceInfo: PropTypes.object.isRequired,
};

export default Carousel;
