const backgroundsData = [
	{
		id: 'outside',
		modifiers: [
			{
				id: 'top',
			},
			{
				id: 'bottom'
			}
		],
	},
	{
		id: 'worksite',
		modifiers: [
			{
				id: 'none'
			},
			{
				id: 'worksiteItems1'
			},
			{
				id: 'worksiteItems2'
			},
			{
				id: 'movedLeft'
			}
		]
	},
	{
		id: 'worksite-2',
		modifiers: [
		]
	}
];

export {backgroundsData};