import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { playerUiTexts } from 'data/ui-texts/player-ui-texts';
import {scrollTo} from 'helpers/scroll-helper';
import {renderMarkdown} from 'helpers/text-helper';
import {shuffleArray} from 'helpers/array-helper';
import { getText } from 'helpers/language-helper';
import Button from 'components/ui/button/button';
import Carousel from './carousel';
import Audio from 'components/ui/audio/audio';
import Character from 'components/ui/character/character';
import './dialogue.scss';

const Dialogue = (props) => {
	const {
		languageId,
		playerTaskData, 
		taskData,
		deviceInfo,
		updateLoggedTime,
		handleInstantTaskEffects, 
		handleCompleteTask
	} = props;

	/* Timeout */
	let timeout = null;	
	let timeout2 = null;

	/* Pause */
	const [isPaused, setIsPaused] = useState(false);

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Show next step button (if next step is final page) */
	const [nextStepId, setNextStepId] = useState(null);

	/**
	 * Get first dialogue step data
	 * @returns {object} dialogueStepData
	 */
	const getFirstDialogueStepData = () => {
		let stepData = null;
		if (taskData && taskData.steps && taskData.steps.length > 0) {
			/* Task completed, get from player data */
			if (
				isCompleted && 
				playerTaskData && 
				playerTaskData.lastStepId &&
				taskData.steps.some((s) => {return s.id === playerTaskData.lastStepId;})
			) {
				stepData = JSON.parse(JSON.stringify(
					taskData.steps.find((s) => {return s.id === playerTaskData.lastStepId;})
				));
			} else {
				/* Get first step */
				stepData = JSON.parse(JSON.stringify(taskData.steps[0]));
			}
		}
		
		/* Shuffle options */
		if (stepData && stepData.options) {
			stepData.options = shuffleArray(stepData.options);
		}
		return stepData;
	};

	/* Get selected options */
	const getSelectedOptions = () => {
		if (isCompleted && playerTaskData && playerTaskData.selectedOptions) {
			return playerTaskData.selectedOptions;
		}
		return [];
	};

	/**
	 * Prepare dialogue
	 * @returns {array} dialogueArr
	 */
	const prepareDialogue = () => {
		if (isCompleted) return [];
		const firstDialogueStepData = (taskData && taskData.steps && taskData.steps.length > 0 
			? taskData.steps[0] : null);
		let dialogueArr = [];
		if (firstDialogueStepData && firstDialogueStepData.type && firstDialogueStepData.text) {
			dialogueArr.push({
				id: firstDialogueStepData.id,
				person: 'customer', 
				character: firstDialogueStepData.character ? firstDialogueStepData.character : taskData.characterId,
				image: firstDialogueStepData.image ? firstDialogueStepData.image : 'neutral',
				type: firstDialogueStepData.type, 
				text: firstDialogueStepData.text
			});
		}

		return dialogueArr;
	};

	/**
	 * Get points
	 * @returns {number} points
	 */
	const getPoints = () => {
		let points = 0;
		if (isCompleted && playerTaskData) points = playerTaskData.points;
		return points;
	};


	/* Track step id, dialogue array, show / hide options & points */
	const [dialogueStepData, setDialogueStepData] = useState(null);
	const [dialogueArr, setDialogueArr] = useState(null);
	const [showOptions, setShowOptions] = useState(true);
	const [points, setPoints] = useState(getPoints());

	/* Track all selected options */
	const [selectedOptions, setSelectedOptions] = useState([]);

	/**
	 * Select an option (answer or action)
	 * @param {number} optionId 
	 */
	const selectOption = (optionId) => {
		/* Cannot play if game is paused */
		if (isPaused) return;

		/* Pause game */
		setIsPaused(true);

		/* Update logged time */
		updateLoggedTime();

		/* Update selected options */
		const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
		newSelectedOptions.push({stepId: dialogueStepData.id, optionId: optionId});
		setSelectedOptions(newSelectedOptions);
		
		/* Get option data */
		const dialogueOptionData = (dialogueStepData && dialogueStepData.options
			? dialogueStepData.options.find((option) => {return option.id === optionId;})
			: null
		);
		if (dialogueOptionData) {
			/* Update dialogue */
			let newDialogueArr = JSON.parse(JSON.stringify(dialogueArr));
			if (dialogueOptionData.dialogueEffects && dialogueOptionData.dialogueEffects.length > 0) {
				/* Use effects (dialogue and / or action) */
				dialogueOptionData.dialogueEffects.forEach((dialogueEffect) => {
					newDialogueArr.push({
						person: 'player', 
						type: dialogueEffect.type, 
						text: dialogueEffect.text,
						id: dialogueOptionData.id,
					});
				});
			} else {
				/* Use default option text (dialogue or action) */
				if (dialogueStepData.optionType && dialogueOptionData.text) {
					newDialogueArr.push({
						person: 'player', 
						type: dialogueStepData.optionType, 
						text: dialogueOptionData.text,
						id: dialogueOptionData.id,
					});
				}
			}
			setDialogueArr(newDialogueArr);

			/* Effects */
			let newPoints = points;
			let instantEffects = [];
			if (dialogueOptionData.effects && dialogueOptionData.effects.length > 0) {
				dialogueOptionData.effects.forEach((effect) => {
					if (effect.type === 'points') {
						/* Effect: points */
						newPoints += effect.value;	
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						instantEffects.push(effect);
					}
				});
			}

			/* Update points */
			setPoints(newPoints);
			
			/* Instant effects */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);

			/* Go to next step or stay at current */
			if (dialogueOptionData.nextStepId) {
				const nextDialogueStepData = taskData.steps.find((step) => {
					return step.id === dialogueOptionData.nextStepId;
				});
				if (nextDialogueStepData && nextDialogueStepData.isFinalStep) {
					/* Stay at the step before the final step until player clicks "next" button */
					setNextStepId(dialogueOptionData.nextStepId);
				} else {
					/* Go to next dialogue step */
					const nextDialogueStepId = dialogueOptionData.nextStepId;
					goToNextDialogueStep(nextDialogueStepId, newDialogueArr, newPoints);
				}
			} else {
				/* Stay at this step (selected option not approved) */
				setIsPaused(false);
			}
		} else {
			setIsPaused(false);
		}
	};

	/**
	 * Go to next dialogue step
	 * @param {number} nextDialogueStepId 
	 * @param {array} newDialogueArr 
	 * @param {number} newPoints
	 */
	const goToNextDialogueStep = (nextDialogueStepId, newDialogueArr, newPoints) => {
		const nextDialogueStepData = taskData.steps.find((step) => {return step.id === nextDialogueStepId;});
		if (nextDialogueStepData && nextDialogueStepData.options) {
			nextDialogueStepData.options = shuffleArray(nextDialogueStepData.options);
		}
		let updatedDialogueArr = JSON.parse(JSON.stringify(newDialogueArr));
		if (nextDialogueStepData) {
			/* Hide options */
			setShowOptions(false);
			/* Update dialogue */
			if (nextDialogueStepData && nextDialogueStepData.type && nextDialogueStepData.text) {
				updatedDialogueArr.push({
					person: 'customer', 
					character: nextDialogueStepData.character ? nextDialogueStepData.character : taskData.characterId,
					image: nextDialogueStepData.image ? nextDialogueStepData.image : 'neutral',
					type: nextDialogueStepData.type, 
					text: nextDialogueStepData.text,
					id: nextDialogueStepData.id
				});
				setDialogueArr(updatedDialogueArr);
			}

			/* Effects */
			let totalPoints = newPoints;
			let instantEffects = [];
			if (nextDialogueStepData.effects && nextDialogueStepData.effects.length > 0) {
				nextDialogueStepData.effects.forEach((effect) => {
					if (effect.type === 'points') {
						/* Effect: points */
						totalPoints += effect.value;	
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						instantEffects.push(effect);
					}
				});
			}

			/* Update dialogue step id */
			setDialogueStepData(nextDialogueStepData);
			setIsPaused(false);

			/* Update points */
			if (totalPoints !== points) setPoints(totalPoints);

			/* Instant effects */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);

			/* Check if task is completed */
			if (
				nextDialogueStepData && 
				nextDialogueStepData.isFinalStep === true && 
				nextDialogueStepData.autoContinue === true
			) {
				completeTask(nextDialogueStepData.id, totalPoints);
			}

			/* Show options */
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => {setShowOptions(true);}, 100);

			/* Auto-nav to next dialogue step */
			if (
				nextDialogueStepData && 
				nextDialogueStepData.nextStepId && 
				nextDialogueStepData.autoContinue === true
			) {
				if (timeout2) clearTimeout(timeout2);
				timeout2 = setTimeout(() => {
					goToNextDialogueStep(nextDialogueStepData.nextStepId, updatedDialogueArr, totalPoints);
				}, 1500);
			}
		} else {
			setIsPaused(false);
		}
	};


	/**
	 * Complete task
	 * @param {string} lastDialogueStepId
	 * @param {number} newPoints
	 */
	const completeTask = (lastDialogueStepId, newPoints) => {
		/* Errors */
		const errors = 0;

		/* Save completed task */
		handleCompleteTask(
			'dialogue', 
			newPoints, 
			errors, 
			[],
			{lastStepId: lastDialogueStepId, selectedOptions}
		);
	};


	/**
	 * Smooth scroll to dialogue bottom
	 */
	const scrollToBottom = () => {
		var div = document.getElementById('dialogueLines');
		if (div) {
			scrollTo(div, (div.scrollHeight - div.clientHeight), 0.1, null);
		}
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		// ...

		return () => {
			/* Component will unmount */
			if (timeout) clearTimeout(timeout);
			if (timeout2) clearTimeout(timeout2);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		scrollToBottom();
	}, [dialogueArr]);

	/**
	 * New dialogue task, reset
	 */
	useEffect(() => {
		setDialogueStepData(getFirstDialogueStepData());
		setSelectedOptions(getSelectedOptions());
		setDialogueArr(prepareDialogue());
		setShowOptions(true);
		setPoints(getPoints());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Check if last page */
	const isLastPage = (dialogueStepData && dialogueStepData.isFinalStep === true);

	/* Result */
	if (isLastPage) {
		return (
			<div className={'Dialogue result' + (taskData.resultBackground ? ' ' + taskData.resultBackground : '')}>
				
				{dialogueStepData.character && 
					<div className={'Dialogue-character ' + dialogueStepData.character}>
						<Character 
							page="dialogue-result" 
							characterId={dialogueStepData.character + (dialogueStepData.imageFlip ? ' flipped' : '')} 
						/>
					</div>
				}
				{dialogueStepData.characters && dialogueStepData.characters.map((character, index) => {
					return (
						<div key={index} className={'Dialogue-character ' + character + ' pos-' + (index + 1)}>
							<Character 
								page="dialogue-result" 
								characterId={character} 
							/>
						</div>
					);
				})}
				{dialogueStepData.items && dialogueStepData.items.map((itemId, index) => {
					return (
						<div key={index} className={'Dialogue-item ' + itemId + ' pos-' + (index + 1)} />
					);
				})}
				<div className={'Dialogue-resultText ' + dialogueStepData.type + ' ' + deviceInfo.orientation}>
					<span>{getText(dialogueStepData.text, languageId)}</span>
				</div>
			</div>
		);
	}

	/* Dialogue */
	return (
		<div className={'Dialogue'}>
			<div className={'Dialogue-wrap ' + deviceInfo.orientation}>
				<div className={'Dialogue-header ' + taskData.image}>
					{taskData.image &&
						<div className={'Dialogue-headerImage ' + taskData.image}/>
					}
					<div className={'Dialogue-headerText ' + taskData.image + ' ' + deviceInfo.orientation}>
						{renderMarkdown(getText(taskData.introText, languageId))}
					</div>
					<div className="Dialogue-headerAudio">
						<Audio 
							type='task-intro'
							color='blue'
							fileName={languageId + '-' + taskData.taskId}
						/>
					</div>
				</div>
				{/* Dialogue (speech / action bubbles) */}
				<div className={'Dialogue-dialogue ' + deviceInfo.orientation}>
					<div id="dialogueLines" className="Dialogue-lines">
						{dialogueArr && dialogueArr.map((line, index) => {
							if (!line.text) return null;

							return (
								<div key={index} className={'Dialogue-line ' + line.person + ' ' + line.type}>
									<div className={
										'Dialogue-personAvatar ' + 
										line.person + 
										' ' +
										line.character +
										(taskData.character ? ' ' + taskData.character : '') + 
										(line.image ? ' ' + line.image : '')
									}/>
									<div className={'Dialogue-bubble ' + deviceInfo.orientation}>
										<span>{getText(line.text, languageId)}</span>
										<div className="Dialogue-bubbleAudio">
											<Audio 
												type='task-intro'
												color='blue'
												fileName={languageId + '-' + taskData.taskId + '-' + line.id}
											/>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				{/* Bottom panel */}
				<div className={'Dialogue-panel ' + deviceInfo.orientation}>
					{/* Options */}
					{(
						showOptions && 
						dialogueStepData && 
						!nextStepId && 
						dialogueStepData.options && 
						dialogueStepData.options.length > 0
					) && 
						<div className={'Dialogue-options'}>
							<div className="Dialogue-optionsText">
								<span>{getText(dialogueStepData.optionPrompt, languageId)}</span>
							</div>
							<div className="Dialogue-optionsCarousel">
								<Carousel 
									taskId={taskData.taskId}
									currentStepId={dialogueStepData.id}
									languageId={languageId}
									optionType={dialogueStepData.optionType}
									options={dialogueStepData.options} 
									selectOption={selectOption}
									deviceInfo={deviceInfo}
								/>
							</div>
						</div>
					}
				</div>
			</div>
			
			{/* Next dialogue step btn */}
			{(
				!isCompleted && 
				((dialogueStepData && dialogueStepData.nextStepId && !dialogueStepData.autoContinue) || nextStepId) 
			) && 
				<div className={'Dialogue-nextBtn ' + deviceInfo.orientation}>
					<Button 
						classes={['next', 'animate']}
						text={getText(playerUiTexts.continue, languageId)}
						onClick={() => {
							goToNextDialogueStep(
								(nextStepId ? nextStepId : dialogueStepData.nextStepId), 
								dialogueArr, 
								points
							);
						}}
					/>
				</div>
			}
		</div>
	);
};

Dialogue.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired
};

export default Dialogue;
