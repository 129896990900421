const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Velkommen til sikkerhedstræning. Du skal igennem to moduler. Jeg er din leder og hjælper dig gennem spillet. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-2',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Spillet handler om sikkerhed på byggepladsen. Noget af det ved du sikkert i forvejen, men det er god viden at repetere.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-point',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-three-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Du skal have tre stjerner for at gå videre til anden del af træningen.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-general-things',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Lad os starte med nogle generelle ting.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-typical-work-accidents',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvad er de typiske årsager til arbejdsulykker?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Manglende omtanke`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Rod`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hastværk`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kollegaer`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej, de fleste ulykker er faktisk ene-ulykker.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Farlige stoffer`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Heldigvis ikke. De fleste er bedre til at passe på, når de ved, at de arbejder med noget farligt.`,
								en: ``,
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-safety-responsibility',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvis du for eksempel skal arbejde i en lejlighed der er ved at blive istandsat, hvem har så ansvaret for sikkerheden?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Dig selv`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Men det er alles ansvar at passe på hinanden.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Jeudan`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. I sidste ende har vi ansvaret`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Arbejdsmiljørepræsentanten`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Arbejdstilsynet`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-work-accident',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvad vil omkostningen ved arbejdsulykker typisk være?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Skader og fravær`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Økonomi`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Tid`,
					en: ``
				}, 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Når der sker en ulykke stopper arbejdet, og det kan i sidste ende gøre at vi skal arbejde hurtigere for at nå tingene.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Manglende tryghed`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Rigtigt. Der er ingen som har lyst til at arbejde et sted, hvor der sker mange ulykker.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Bøder`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-building-plots-dangerous',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Byggepladser kan være farlige, så i værste fald kan arbejdsulykker lede til lang tids sygemelding eller tabt arbejdsevne.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-buildling-plots-dangerous-2',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Og det går bare ikke - man skal ikke blive syg af at gå på arbejde hos os. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-but-first',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Lige om lidt går vi ind på en byggeplads. Men først:`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-avoiding-accidents',
		background: 'outside',
		backgroundModifier: 'bottom',
		layout: 's1-m1-avoiding-accidents',
		subtype: 'images',
		text: {
			da: `Hvad er det bedste værktøj til at forebygge arbejdsulykker?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Hjerne
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det lyder måske lidt plat, men langt de fleste ulykker kan undgås ved at bruge ti ekstra sekunder til at tænke over situationen. `,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Rundsav
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Hard hat
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Afspærringstape
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-on-to-worksite',
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Lad os kigge indenfor på en byggeplads. Det kan for eksempel være en lejlighed, der er ved at blive istandsat. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-what-to-do-before',
		background: 'worksite',
		text: {
			da: `Hvad er det første, du skal gøre, inden du går i gang med arbejdet?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Lave en risikovurdering`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Brug et øjeblik på at vurdere, om der er noget uventet eller bare noget, som ikke helt spiller. `,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Drikke en kop kaffe`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det kan du gøre i pausen. `,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `High five kollegaerne`,
					en: ``
				}, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Vaske hænder`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-appartment',
		layout: 's1-m1-appartment',
		background: 'worksite',
		text: {
			da: `Kig på billedet, og se om du kan spotte problemerne. Klik på de 3 problemer. Klik OK, når du er klar, eller hvis du ikke kan finde dem alle.  `,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'missing-door',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Præcis. Risikoen for at falde ud er måske lille, men det ville være ekstremt alvorligt, hvis man gjorde.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trash'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'person'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-close-look',
		background: 'worksite',
		backgroundModifier: 'worksiteItems1',
		characters: ['manager pose-1'],
		text: {
			da: `Lad os lige kigge nærmere på et par af problemerne. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-whats-the-problem',
		background: 'worksite',
		backgroundModifier: 'worksiteItems1',
		text: {
			da: `Hvad er problemet med vinkelsliberen her?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Mangler sikkerhedsbriller`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Øjnene er super skrøbelige, og en gnist fra en vinkelsliber kan sagtens lave skade. `,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Dårlig håndstilling`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Mangler hjelm`,
					en: ``
				}, 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du behøver ikke hjelm for at bruge en vinkelsliber`,
								en: ``,
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-how-to-solve',
		background: 'worksite',
		backgroundModifier: 'worksiteItems2',
		text: {
			da: `Hvordan løser man det her problem?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ryd op efter dig selv`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Faldskader på grund af rod er en meget almindelig arbejdsulykke.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Lærlingen rydder op`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Ryd op efter endt arbejdsdag`,
					en: ``
				}, 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er ikke en dårlig idé, men rod der ligger på adgangsveje skal flyttes så hurtigt som muligt. `,
								en: ``,
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-safety-is-about',
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Sikkerhed handler i høj grad om at forebygge og om at minimere risiko. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-work-on-wall',
		background: 'worksite',
		text: {
			da: `Hvis du skal arbejde i væggen nær en installation, hvad er så vigtigt?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `At afbryderen er slået fra`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `At bruge isoleret værktøj`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `At bære gummisåler`,
					en: ``
				}, 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du skal bare have dine helt almindelige sikkerhedssko på.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `At råbe "timber!"`,
					en: ``
				}, 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej. Bare nej.`,
								en: ``,
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-electric-shock',
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Hvis en medarbejder får elektrisk stød, så er der en fast procedure som skal følges. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-procedure-electric-shock',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvad er proceduren, hvis man får elektrisk stød? Sæt nedenstående i den rigtige rækkefølge. Tryk OK, når du er klar. `,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Arbejdet stoppes`,
					en: ``
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Personen køres på skadestuen`,
					en: ``,
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Personen måles og observeres`,
					en: ``,
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Ulykken registreres hos Jeudan`,
					en: ``,
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-be-carefull',
		background: 'worksite',
		characters: ['manager pose-2'],
		text: {
			da: `Ud over at det koster tid, så er det aldrig rart at skulle afbryde arbejdet for at tage på skadestuen. Så vær ekstra forsigtig nær elektriske installationer. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-almost-done',
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Du er næsten færdig med første modul. Men først et par generelle spørgsmål om arbejdsulykker.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-not-severe-enough',
		background: 'worksite',
		text: {
			da: `Hvis der sker en ulykke, skal du så melde det til nogen?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja, til nærmeste leder`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Præcis. Hvis vi skal undgå ulykker, så skal vi vide, når de sker. Ellers kan vi ikke forebygge ordentligt. `,
								en: ``,
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej, det er ikke nødvendigt`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Ja, til politiet`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kun hvis det er en lærling`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-done',
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til. `,
			en: ``
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};