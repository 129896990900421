const languagesData = [
	{
		id: 'da',
		title: {
			da: 'Dansk', 
			en: 'Dansk', // language names are always in their own language
		},
		localization: 'da-DA'
	}
];

export {languagesData};