const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-module-2',
		showProgressBar: false,
		background: 'worksite',
		characters: ['manager pose-2'],
		text: {
			da: `Velkommen til andet modul af sikkerhedstræningen. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-close-look',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Nu skal vi kigge nærmere på nogle specifikke situationer. Husk du skal have tre stjerner for at gennemføre. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-correct-equipment',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Noget af det, som for alvor forebygger ulykker, er at bruge rigtigt udstyr på den rigtige måde. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-safety-equipment',
		background: 'worksite',
		layout: 's1-m2-safety-equipment',
		subtype: 'images',
		text: {
			da: `Hvad skal du have på af sikkerhedsudstyr, når du skal slibe et område?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Høreværn
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Sikkerhedsbriller
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Maske
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Hjelm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Skudsikker vest
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Svejsemaske
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-safety-equipment-2',
		background: 'worksite',
		layout: 's1-m2-safety-equipment',
		subtype: 'images',
		text: {
			da: `Og hvad hvis du skal være i samme rum som en der sliber?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Høreværn
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Sikkerhedsbriller
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Maske
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Hjelm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Skudsikker vest
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Svejsemaske
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-circular-saw',
		background: 'worksite',
		layout: 's1-m2-circular-saw',
		subtype: 'images',
		text: {
			da: `Du skal save med en rundsav. Hvad er den korrekte arbejdsstilling.`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Person der står, relativt ret, skærer væk fra sig selv. Skærer vandret
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Samme, men skærer lodret
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Lavt bord, lænet frem
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // To personer. En holder, den anden skærer imod den person
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-box-cutting',
		background: 'worksite',
		layout: 's1-m2-box-cutting',
		subtype: 'images',
		text: {
			da: `Hvad med når du skal åbne kasser med en kniv. Tryk på det rigtige billede. `,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Skærer væk fra sig selv
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Skærer væk fra sig selv, men holder hånd i skæreretning
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Skærer mod sig selv
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-easy-knife-injury',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Det er utroligt nemt at komme til skade med knive. Og hvis man for eksempel rammer sin hånd uheldigt, kan det have store konsekvenser. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-correct-workposition-ladder',
		background: 'worksite',
		layout: 's1-m2-correct-workposition-ladder',
		subtype: 'images',
		text: {
			da: `Hvad er den rigtige arbejdsstilling på en stige?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Korrekt brug af stige
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hænger ud over stigen for at nå
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er meget normalt at gøre sådan for at spare tid. Men hvis du falder ned, eller hvis stigen vælter, så kommer du både til skade og bruger længere tid. `,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Trappestige lænet op ad væg, ikke foldet ud
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Trappestige kun foldet lidt ud så kæden er slap
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-2-issues',
		layout: 's1-m2-2-issues',
		background: 'worksite-2',
		text: {
			da: `Spot de to problemer på billedet. Tryk OK, når du har dem, eller hvis du ikke kan finde dem begge.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'ladder', // Wienerstige opsat foran
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hole', // hole
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Her skal der selvfølgelig være afspærret, så man ikke kan falde ned. Det er især vigtigt i forhold til gæster, som ikke er vant til at færdes på en byggeplads. `,
								en: ``,
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-old-appartments',
		background: 'worksite',
		text: {
			da: `Hos Jeudan arbejder vi tit i gamle lejligheder, og derfor er der en ting, vi skal være ekstra opmærksomme på. Hvad er det?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: 'Asbest',
					en: ''
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: 'Råd',
					en: ''
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: 'Støv',
					en: ''
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: 'Duer',
					en: ''
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-asbestos-dust',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-2'],
		text: {
			da: `Asbeststøv er sundhedsskadeligt at indånde. Heldigvis er det muligt at håndtere de risici, der er ved at arbejde med asbest.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-screening',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-2'],
		text: {
			da: `Generelt laver vi screeninger inden arbejdet. Og hvis vi finder asbest, så får vi eksperter til at fjerne det, inden I skal arbejde. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-safety-doubt',
		background: 'worksite',
		text: {
			da: `Hvis du er i tvivl om, hvorvidt et område er sikkert at arbejde på, hvor kan du så søge mere information?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: 'I miljørapporten',
					en: ''
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Lige præcis. Her står der, hvis du skal være særligt opmærksom på noget. `,
								en: ``,
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: 'På google',
					en: ''
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: 'På Jeudans hjemmeside',
					en: ''
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: 'På asbesthuset.dk',
					en: ''
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er en god ressource, men den hjælper ikke i den specifikke situation.`,
								en: ``,
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-asbestos-doubt',
		background: 'worksite',
		text: {
			da: `Nogle gange skal der uforudset åbnes til en skunk, tages et loft ned, åbnes til rørkanaler eller lignende. Her kan du støde på asbest, og du skal derfor være særligt opmærksom. Har du mistanke om, at der er asbest involveret i arbejdet, eller er du i tvivl, hvad skal du så gøre?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: 'Stoppe arbejdet og kalde på en formand',
					en: ''
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: 'Melde det til en formand efter arbejde',
					en: ''
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: 'Tage billeder inden arbejdet startes',
					en: ''
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: 'Huske at bære maske',
					en: ''
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-asbest-issues',
		layout: 's1-m2-asbest-issues',
		background: 'worksite',
		text: {
			da: `På billedet her, hvilke to steder skal du så være særligt opmærksom i forhold til asbest? Tryk på de to områder. Tryk OK, når du har dem, eller hvis du ikke kan finde dem begge.</b>`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'roofplates', // Grå tagplader, bølgede med vaffelmønster bagpå
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pipes', // Gamle rør med krakeleret beklædning
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-asbest-roofplates',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-2'],
		text: {
			da: `Der kan også være asbest i loftsplader, fugemasse mellem badeværelsesfliser og meget andet. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-remember',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `Så husk: hvis du åbner til noget, som ikke er dækket af miljørapporten, så tænk dig om. Og tal eventuelt med en kollega. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-summing-up',
		layout: 's1-m2-summing-up',
		background: 'worksite',
		backgroundModifier: 'movedLeft',
		text: {
			da: `Lad os lige opsummere. Klik på de 3 problemer på billedet. Klik OK, når du er klar, eller hvis du ikke kan finde dem alle.  `,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'power', // Person skal til at bore ind ved siden af elskab som tydeligt er tændt - gør det tydeligt at han skal bore i væggen under tavlen
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja, installationen skal afbrydes, inden der arbejdes. `,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'mess', // Rod på gulvet til venstre på skærmen, fjern rod bag person
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja, det er nemt at falde over det der rod.`,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'no-mask', // Person med bor har ikke maske
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set. Slibning støver en masse, så andre i rummet skal også bære maske. Og så bør han selvfølgelig bære høreværn.`,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'no-earprotection', // Person der sliber har ikke høreværn
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-safety',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-2'],
		text: {
			da: `Sikkerhed handler også om at passe på hinanden. `,
			en: ``,
		}
	}),
	/* DIALOGUE START */
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 's1-m2-dialogue-safety-goggles',
		background: 'worksite',
		image: 'colleague-angle-grinder',
		maxPoints: 10,
		introText: {
			da: `En kollega skal til at arbejde med en vinkelsliber. Du lægger mærke til, at han ikke har sikkerhedsbriller på. <br /> Hvad siger du?`,
			en: ``
		},
		introTextType: 'speech',
		introCharacter: 'manager-2',
		introShowAvatar: true,
		character: 'colleague',
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-01',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Skal du ikke have briller på?',
							en: ''
						},
						nextStepId: 'JD-02',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 5
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Er det sikkert det der?',
							en: ''
						},
						nextStepId: 'JD-03',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 2
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3,
						text: {
							da: 'Så er det snart frokosttid.',
							en: ''
						},
						nextStepId: 'JD-04'
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-02',
				optionPrompt: {
					da: 'Vælg dit svar'
				},
				text: {
					da: 'Det er ikke nødvendigt - jeg skal bare lige skære noget hurtigt.',
					en: ''
				},
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Okay.',
							en: ''
						},
						nextStepId: 'JD-05',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Men derfor kan du jo godt komme til skade.',
							en: ''
						},
						nextStepId: 'JD-06',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 5
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-03',
				optionPrompt: {
					da: 'Vælg dit svar'
				},
				text: {
					da: 'Hvad mener du?',
					en: ''
				},
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Ikke noget, gå bare i gang.',
							en: ''
						},
						nextStepId: 'JD-05',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Ja, at skære uden sikkerhedsbriller.',
							en: ''
						},
						nextStepId: 'JD-02',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 3
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-04',
				optionPrompt: {
					da: 'Vælg dit svar'
				},
				text: {
					da: 'Ja, det bliver skønt. Men jeg skærer nu, hvis du vil have høreværn på.', 
					en: ''
				},
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Nåja, det skal jeg huske.',
							en: ''
						},
						nextStepId: 'JD-07',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 1
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Nåja, tak. Skal du ikke også huske briller?',
							en: ''
						},
						nextStepId: 'JD-08',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 2
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-05',
				optionPrompt: {
					da: 'Vælg dit svar'
				},
				type: 'action',
				text: {
					da: 'Din kollega går i gang. Pludselig bander han og stopper.',
					en: ''
				},
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Hvad skete der?',
							en: ''
						},
						nextStepId: 'JD-09',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 1
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Er du okay?',
							en: ''
						},
						nextStepId: 'JD-10',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 1
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-06',
				text: {
					da: `Ja, det er selvfølgelig rigtigt.`,
					en: ``
				},
				nextStepId: 'JD-14'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-07',
				optionPrompt: {
					da: 'Vælg dit svar'
				},
				type: 'action',
				text: {
					da: 'Du tager høreværn på og din kollega går i gang. Pludselig bander han og stopper.',
					en: ''
				},
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Hvad skete der?',
							en: ''
						},
						nextStepId: 'JD-09',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 1
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Er du okay?',
							en: ''
						},
						nextStepId: 'JD-10',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 1
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-08',
				optionPrompt: {
					da: 'Vælg dit svar'
				},
				text: {
					da: 'Njah, det behøver jeg ikke. Jeg skal bare skære noget hurtigt.', 
					en: ''
				},
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Okay. ',
							en: ''
						},
						nextStepId: 'JD-05',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Men derfor kan du jo godt komme til skade.',
							en: ''
						},
						nextStepId: 'JD-06',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 2
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-09',
				optionPrompt: {
					da: 'Vælg dit svar'
				},
				text: {
					da: 'Jeg fik bare en gnist på kinden. Gjorde sgu ondt.', 
					en: ''
				},
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Godt du er okay.',
							en: ''
						},
						nextStepId: 'JD-12',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 1
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Godt det kun var kinden.',
							en: ''
						},
						nextStepId: 'JD-11',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 2
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-10',
				optionPrompt: {
					da: 'Vælg dit svar'
				},
				text: {
					da: 'Ja ja, jeg fik bare en gnist på kinden.', 
					en: ''
				},
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: {
							da: 'Godt du er okay.',
							en: ''
						},
						nextStepId: 'JD-12',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 1
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: {
							da: 'Godt det kun var kinden.',
							en: ''
						},
						nextStepId: 'JD-11',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points', 
								value: 2
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-11',
				text: {
					da: `Ja, jeg må hellere finde et par sikkerhedsbriller.`,
					en: ``
				},
				nextStepId: 'JD-14'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-12',
				text: {
					da: `Ja. Nå, arbejdet laver ikke sig selv. `,
					en: ``
				},
				nextStepId: 'JD-13'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-13',
				showPoints: true,
				isFinalStep: true,
				text: {
					da: `Din kollega fortsætter arbejdet. Heldigvis sker der ikke mere. `,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'JD-14',
				showPoints: true,
				isFinalStep: true,
				text: {
					da: `Din kollega tager sikkerhedsbriller på og fortsætter arbejdet. `,
					en: ``
				},
			}),
		]		
	}),
	/* DIALOGUE END */
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-almost-done',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-2'],
		text: {
			da: `Du er nu næsten igennem træningen.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-safety-culture',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-1'],
		text: {
			da: `De sidste spørgsmål handler om sikkerhedskultur. Her er der ikke rigtige og forkerte svar, og du svarer anonymt.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-i-know-enough',
		background: 'worksite',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende?</b><br /><br />Jeg føler, at jeg har tilstrækkelig viden om sikkerhed på byggepladsen.`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: ``,
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-safety-every-day',
		background: 'worksite',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende?</b><br /><br />Vi taler om sikkerhed hver dag før, under og efter arbejdet.`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: ``,
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-do-something',
		background: 'worksite',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende?</b><br /><br />Hvis jeg ser mine kollegaer gøre noget, som truer sikkerheden, så gør jeg noget ved det?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: ``,
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: ``,
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-done',
		showProgressBar: true,
		background: 'worksite',
		characters: ['manager pose-2'],
		text: {
			da: `Så er du igennem træningen. Hvis du har fået tre stjerner er du færdig for denne gang. Ellers må du tage det her modul en gang til. 
				<br /><br />God arbejdslyst derude!`,
			en: ``,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};