const modulesData = [
	{
		id: 's1-m1',
		background: '',
		title: {
			da: 'Sikkerhed A',
			en: 'Safety A',
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
	{
		id: 's1-m2',
		background: '',
		title: {
			da: 'Sikkerhed B',
			en: 'Safety B',
		},
		minStars: 3,
		requiredModuleIds: ['s1-m1'],
		tasks: require('./modules/module-2').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's1-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Sikkerhed',
			en: 'Safety',
		},
		moduleIds: [
			's1-m1',
			's1-m2'
		]
	}
];

export {
	modulesData,
	moduleGroups
};