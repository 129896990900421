import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {getCookie} from 'helpers/cookie-helper';
import {getText} from 'helpers/language-helper';
import {errorUiTexts} from 'data/ui-texts/error-ui-texts';
import LoginFacilitator from './login-facilitator';

class LoginFacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			password: null,
			feedback: null,
			isLoading: false,
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	};
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		if (event) event.preventDefault();
		this.setState({isLoading: true, fedback: null}, () => {
			let feedback = null;

			/* Check if cookies are accepted */
			const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
			if (!cookieConsent || cookieConsent.length === 0) {
				feedback = getText(errorUiTexts.cookiesNotAccepted, this.props.languageId);
			}

			/* Check if e-mail / password is okay */
			const email = this.state.email;
			const password = this.state.password;
			if (!feedback && (!email || !password)) {
				feedback = getText(errorUiTexts.emptyFields, this.props.languageId);
			}

			if (!feedback) {
				firebase.auth().signInWithEmailAndPassword(email, password)
					.catch((error) => {
						if (error.code === 'auth/user-not-found') {
							feedback = getText(errorUiTexts.emailNotFound, this.props.languageId);
						}
						if (error.code === 'auth/wrong-password') {
							feedback = getText(errorUiTexts.invalidEmailPasswordFormat, this.props.languageId);
						}
						if (error.code === 'auth/invalid-email') {
							feedback = getText(errorUiTexts.invalidEmail, this.props.languageId);
						}
						if (!feedback) {
							console.error(error);
							feedback = getText(errorUiTexts.unknownError, this.props.languageId);
						}
						this.setState({isLoading: false, feedback});
					});
			} else {
				this.setState({isLoading: false, feedback});
			}
		});
	};


	render = () => {
		return (
			<LoginFacilitator
				isLoading={this.state.isLoading}
				languageId={this.props.languageId}
				email={this.state.email}
				password={this.state.password}
				feedback={this.state.feedback}
				handleLogin={this.handleLogin}
				handleInput={this.handleInput}
				deviceInfo={this.props.deviceInfo}
				setFacilitatorComponentType={this.props.setFacilitatorComponentType}
			/>
		);
	};
}

LoginFacilitatorController.propTypes = {
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	setFacilitatorComponentType: PropTypes.func.isRequired,
};

export default LoginFacilitatorController;