/* Instructions */
const instructionsTemplate = {
	mainTemplate: {
		type: 'instructions',
		isSolveToContinue: false,
		showProgressBar: true,
		background: null,
		backgroundModifier: null,
		title: null,
		text: null,
		textType: 'speech', // 'info', 'speech',
		characters: ['manager pose-1 coat-1 hairnet-1'],
	}
}

/* Image */
const imageTemplate = {
	mainTemplate: {
		type: 'image',
		isSolveToContinue: false,
		showProgressBar: true,
		image: null,
		text: null,
	}
}

/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		type: 'multiple-choice',
		isSolveToContinue: true,
		isDilemma: false,
		subtype: null, // null, images
		showRequiredNumberOfAnswers: true, // set to false to hide the "choose x answers"
		numberOfAnswersToSelect: 1, // used if isDilemma
		maxPoints: 0, // used if isDilemma
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		showProgressBar: true,
		image: null,
		text: null,
		options: [],
		doneEffects: []
	},
	optionTemplate: {
		text: null, 
		isCorrect: false,
		effects: []
	}
};

/* Sort */
const sortTemplate = {
	mainTemplate: {
		type: 'sort',
		subtype: null, // null, images
		isSolveToContinue: true,
		showProgressBar: true,
		layout: '',
		text: null,
		image: null,
		categories: [],
		items: []	
	},
	categoryTemplate: {
		title: null,
		text: null,
	},
	itemTemplate: {
		text: null,
		categoryIds: []
	}
};

/* Order */
const orderTemplate = {
	mainTemplate: {
		type: 'order',
		isSolveToContinue: true,
		showProgressBar: true,
		image: null,
		text: null,
		items: []
	},
	itemTemplate: {
		text: null,
		correctOrderNumbers: []
	}
};

/* Organize */
const organizeTemplate = {
	mainTemplate: {
		type: 'organize',
		isSolveToContinue: true,
		showProgressBar: true,
		showSolutionWhenDone: false,
		layout: '',
		title: null,
		text: null,
		items: [],
		rules: [],
		mainContainers: [],
		doneConditions: [],
		doneEffects: []
	},
	itemTemplate: {
		containerId: null, 
		image: null, 
		alt: null,
		correctContainerIds: []
	},
	mainContainerTemplate: {
		title: null,
		containers: []
	},
	containerTemplate: {
		type: '',
		finalItemId: null
	},
	doneConditionTemplate: {
		type: '', // empty-main-container
		mainContainerId: null
	},
	rulesTemplate: {
		type: '', // items-in-correct-containers
	}
};

/* Spot errors */
const spotErrorsTemplate = {
	mainTemplate: {
		type: 'spot-errors',
		background: 'white',
		layout: '',
		isSolveToContinue: true,
		showProgressBar: true,
		doneWhenAllErrorsSpotted: false,
		showDefaultResultPopup: true,
		text: null,
		errors: [],
		doneEffects: []
	},
	errorTemplate: {
		effects: []
	}
};

/* Survey */
const surveyTemplate = {
	mainTemplate: {
		type: 'survey',
		isSolveToContinue: true,
		subtype: null, // null, images
		showRequiredNumberOfAnswers: true, // set to false to hide the "choose x answers"
		minOptionsToSelect: 1,
		maxOptionsToSelect: 1,
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		showProgressBar: true,
		image: null,
		title: null,
		text: null,
		options: [],
		doneEffects: [],
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	},
	optionTemplate: {
		text: null, 
		effects: []
	}
};

/* Match */
const matchTemplate = {
	mainTemplate: {
		type: 'match',
		isSolveToContinue: true,
		showProgressBar: true,
		layout: null,
		text: null,
		categoriesType: 'text', // text, image
		categoriesTitle: null,
		categories: [],
		itemsType: 'text', // text, image
		itemsTitle: null,
		items: [],
	},
	categoryTemplate: {
		text: null,
		itemIds: []
	},
	itemTemplate: {
		text: null,
		effects: []
	}
}

/* Dialogue */
const dialogueTemplate = {
	mainTemplate: {
		type: 'dialogue',
		isSolveToContinue: true,
		showProgressBar: true,
		showPointsIfCompleted: true,
		characterId: null,
		maxPoints: 0,
		introText: '',
		introTextType: 'speech', // info, speech
		introBackground: null,
		introCharacter: 'manager',
		introShowAvatar: false,
		steps: []
	},
	stepTemplate: {
		showPoints: false,
		isFinalStep: false,
		imageFlip: false,
		type: 'dialogue', // dialog, action
		image: 'neutral', // neutral, happy, angry
		character: null,
		characters: null,
		items: null,
		text: null,
		autoContinue: false,
		nextStepId: null, // set if autoContinue is true, unless final step
		optionType: 'dialogue', // dialogue, action
		optionPrompt: 'Choose your answer:', 
		options: [],
		effects: [],
		showAvatar: true, // only used in dialogue end result
		checklistData: null // only used in dialogue end result
	},
	stepOptionTemplate: {
		text: '',
		points: 0,
		dialogueEffects: [],
		effects: [],
		autoContinue: false,
		nextStepId: null // id (continue) or null (stay at step)
	},
	stepOptionEffectTemplate: {
		type: 'dialogue', // dialogue, action
		text: ''
	}
};

export {
	multipleChoiceTemplate,
	instructionsTemplate,
	spotErrorsTemplate,
	organizeTemplate,
	imageTemplate,
	orderTemplate,
	sortTemplate,
	surveyTemplate,
	matchTemplate,
	dialogueTemplate
};
